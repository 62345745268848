import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Button,
  TableContainer,
  Box,
  Backdrop,
  IconButton
} from '@mui/material';

import { RotatingLines } from 'react-loader-spinner';

import PreviewIcon from '@mui/icons-material/Preview';

import { TableListHead, TableListToolbar } from '../../components/table';

import { fetchMainReport } from '../../service/ReportLogService';

import moment from 'moment';

import { filter, isEmpty } from 'lodash';
import { formatEST } from '../../utils/timeConvert';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'report_name', label: 'Report Name', sort: true },
  { id: 'latestRunDate', label: 'Last Date', sort: true },
  { id: 'viewAction', label: 'Last Report', sort: false },
  { id: 'nextScheduleDate', label: 'Next Date', sort: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_item) => _item.report_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const Archive = () => {
  const [archivetData, setArchiveData] = useState([])

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('');

  const [filterName, setFilterName] = useState('');

  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)
  const [content, setContent] = useState("")

  const league = useSelector(state => state.league)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      try {
        if (league.leagueId) {
          setLoading(true)
          const result = await fetchMainReport({ league_id: league.leagueId });
          setLoading(false)
          setArchiveData(result);
        } else {
          navigate("/league")
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredData = applySortFilter(archivetData, getComparator(order, orderBy), filterName);

  const isNotFound = !archivetData.length && !!filterName;

  return (
    <div className='bg-white bg-opacity-10 shadow-lg pb-6 max-w-[calc(95vw)]'>
      <Container>
        <Box sx={{
          width: '94%',
          ml: '3%',
          top: -50,
          mt: -3,
          mb: 3,
          p: '24px 16px',
          opacity: 1,
          background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232))',
          color: 'rgb(255, 255, 255)',
          borderRadius: '0.5rem',
          boxShadow: 'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
        }}>
          <Typography variant='h6'>Your FantasyCast Archive</Typography>
        </Box>

        <Button sx={{ color: 'white' }} size='large' onClick={() => navigate("/report")}>
          Back
        </Button>
        <Card>
          <TableListToolbar key="table-tool-bar" filterName={filterName} onFilterName={handleFilterByName} />

          <TableContainer className='px-6 pb-4' sx={{ maxHeight: "calc(100vh - 400px)" }}>
            <Table stickyHeader>
              <TableListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={archivetData.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {
                  loading ?
                    <TableRow>
                      <TableCell align="center" colSpan={12}>
                        <div className='report-loadingspinner'>
                          <RotatingLines
                            strokeColor="rgb(73, 163, 241)"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    :
                    isNotFound || isEmpty(archivetData) ?
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                              py: 1
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Empty
                            </Typography>
                            {
                              isNotFound ?
                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                                :
                                ''
                            }
                          </Paper>
                        </TableCell>
                      </TableRow>
                      :
                      filteredData.map((row) => {
                        const { report_id, report_name, latestRunDate, content, nextScheduleDate } = row;
                        return (
                          <TableRow hover key={report_id} tabIndex={-1}>
                            <TableCell align='center' component="th" scope="row" padding="none">
                              <Typography variant="inherit">
                                {report_name}
                              </Typography>
                            </TableCell>

                            <TableCell align='center'>{formatEST(latestRunDate)}</TableCell>

                            <TableCell align='center' sortDirection={false}>
                              <IconButton sx={{ color: '#1677ff' }} size="small" color="inherit" onClick={() => { setOpen(true); setContent(content) }}>
                                <PreviewIcon />
                              </IconButton>
                            </TableCell>

                            <TableCell align='center'>{!nextScheduleDate ? "" : formatEST(nextScheduleDate)}</TableCell>
                          </TableRow>
                        );
                      })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        <Backdrop
          sx={{ color: '#fff', backgroundColor: "#3683dc", overflow: "auto", pt: 8, zIndex: (theme) => theme.zIndex.drawer + 1, alignItems: "baseline" }}
          open={open}
          onClick={() => setOpen(false)}
        >
          <Typography variant="caption" display="block" gutterBottom sx={{ paddingLeft: 5, paddingRight: 5, fontSize: 16 }}>
            {content}
          </Typography>
        </Backdrop>
      </Container>
    </div>
  );
}

export default Archive
