import React, { useEffect } from 'react'
import Navbar from './Navbar'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tokens } from '../utils/token';
import { fetchNotification } from '../service/NotificationService';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../state/actions/AuthAction';

const MainLayout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        if (tokens.get()) {
          const response = await fetchNotification();
          dispatch(setCurrentUser({ notifications: response.data.data }));
        }
      } catch (err) {
        console.error(err);
      }
    })();
  })

  return (
    <>
      <header className="items-center absolute top-0 left-0 z-10 w-full">
        <div className="container mx-auto">
          <Navbar />
        </div>
      </header>
      <div className='bg-main-background w-screen h-screen bg-cover bg-center'>
        <div className='flex justify-center items-center pt-36 w-100'>
          <Outlet />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default MainLayout
