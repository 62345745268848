import axios from 'axios'
import { tokens } from '../utils/token'

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/user/auth`,
  headers: {
    'Content-Type': 'application/json',
  },
})

const login_service = async (data) => {
  try {
    let result = await apiClient.post('/login', data)
    tokens.set(result.data.token)
    return Promise.resolve(result)
  } catch (err) {
    throw err;
  }
}

const register_service = async (data) => {
  try {
    let result = await apiClient.post('/register', data)
    return result
  } catch (err) {
    throw err;
  }
}

const forgotPassword = async (data) => {
  try {
    let result = await apiClient.post('/forgot-password', data)
    return result
  } catch (err) {
    throw err;
  }
}

const resetPassword = async (data) => {
  try {
    let result = await apiClient.post('/reset-password', data)
    return result
  } catch (err) {
    throw err;
  }
}

const logout = () => {
  tokens.remove();
  return true;
};

export { login_service, register_service, logout, forgotPassword, resetPassword }
