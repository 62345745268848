import {
  Box,
  Container,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { ProfileDetails } from './ProfileDetails';

const Profile = () => (
  <div className=" bg-white bg-opacity-10 shadow-lg">
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 2,
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{
          width: '94%',
          ml: '3%',
          top: -50,
          mt: -5, // margin-top
          mb: 3,
          p: '24px 16px', // padding
          opacity: 1,
          background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232))',
          color: 'rgb(255, 255, 255)',
          borderRadius: '0.5rem',
          boxShadow: 'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
        }}>
          <Typography variant='h6'>Profile</Typography>
        </Box>
        <div>
          <Grid
            container
            spacing={2}
          >
            <Grid
              xs={12}
              md={12}
              lg={12}
            >
              <ProfileDetails />
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  </div >
);

export default Profile;
