import { SET_CURRENT_REPORT_LOG } from '../actions/types';

const initialState = {
  log_id: ""
};

const LeagueReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_REPORT_LOG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default LeagueReducer