import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import ToggleDays from './ToggleDays';

import { saveSchedule } from '../../service/ScheduleService';
import { useSelector } from 'react-redux';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ScheduleModal({
  voiceData,
  reportId,
  title,
  open,
  setOpen,
  schedule,
  userVoice,
  setUserVoice,
  userSchedule,
  setUserSchedule
}) {
  const league = useSelector(state => state.league)
  const [audioURL, setAudioURL] = useState('')
  // const [voiceLabel, setVoiceLabel] = useState('')
  const [voiceID, setVoiceID] = useState(!isEmpty(userVoice) && !isEmpty(userVoice[reportId]) ? userVoice[reportId] : '')

  const [dayList, setDayList] = useState(!isEmpty(userSchedule) && !isEmpty(userSchedule[reportId]) ? userSchedule[reportId] : []);


  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // console.log(userVoice)

    if (open) {
      let data = !isEmpty(userSchedule) && !isEmpty(userSchedule[reportId]) ? userSchedule[reportId] : []
      let voice = !isEmpty(userVoice) && !isEmpty(userVoice[reportId]) ? userVoice[reportId] : ''
      setVoiceID(voice)
      setDayList(data)
      // console.log(data)
    }

  }, [userSchedule, reportId, open])

  const onSave = async () => {
    try {
      let report_schedule = userSchedule

      if (report_schedule === undefined) {
        report_schedule = {}
      }
      report_schedule[reportId] = dayList
      // setUserSchedule(report_schedule)


      const schedule = await saveSchedule({ report_voice: userVoice, report_schedule: report_schedule, league: league.leagueId });
      // console.log(user)
      setOpen(false);
      setUserVoice(schedule.report_voice)
      setUserSchedule(schedule.report_schedule)
    } catch (error) {
      // Handle the error
      console.error(error);
    }
    // 
    // setActiveSchedule(dayList)
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <FormControl size='small' sx={{ m: 1, width: "90%" }}>
          <InputLabel id="audio-select-small-label">Audio</InputLabel>
          <Select
            labelId="audio-select-small-label"
            id="audio-select-small"
            value={voiceID}
            label="Audio"
            onChange={(event) => {
              const selectVoice = voiceData.filter(voice => voice.value === event.target.value)[0];
              setAudioURL(selectVoice.audioURL)
              setVoiceID(event.target.value)
              setUserVoice({ ...userVoice, [reportId]: event.target.value })
              // setVoiceLabel(event.target.value)
            }}
            size='small'
          >
            <MenuItem key={`voice-0`} value={0} disabled>
              <em>Select voice</em>
            </MenuItem>
            {voiceData.map((voice, idx) =>
              <MenuItem key={`voice-${idx}`} value={voice.value}>{voice.label}</MenuItem>
            )}
          </Select>
        </FormControl>
        {<audio controls src={audioURL} />}
        <ToggleDays schedule={schedule} setDayList={setDayList} days={dayList} selectSchedule={dayList} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}