import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Select, MenuItem, Grid, FormControl, InputLabel } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { generateAudio } from "../../service/ReportLogService"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  minWidth: "90%"
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AudioModal = ({ voiceData, open, setOpen, reportlog_id, rowData }) => {
  const [audioURL, setAudioURL] = useState('')
  const [voiceID, setVoiceID] = useState('')
  const [voiceLabel, setVoiceLabel] = useState('')
  const [audioLoading, setAudioLoading] = useState(false)

  const handleDownload = () => {
    const a = document.createElement('a')
    let url = `${process.env.REACT_APP_SERVER_URL}/api/user/reportlog/audio/download`
    a.href = url
    a.download = url.split('/').pop()
    a.click()
  }

  const handleAudio = async () => {
    setAudioLoading(true)

    await generateAudio({
      // reportlog_id: reportlog_id,
      text: rowData.content,
      voiceID: voiceID,
    })
      .then(res => setAudioLoading(false))
      .catch(err => console.log(err))
  }


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Generate Audio
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ minWidth: "90%" }}>

        <FormControl size='small' sx={{ m: 1, width: "90%" }}>
          <InputLabel id="audio-select-small-label">Audio</InputLabel>
          <Select
            labelId="audio-select-small-label"
            id="audio-select-small"
            value={voiceLabel}
            label="Audio"
            onChange={(event) => {
              const selectVoice = voiceData.filter(voice => voice.value === event.target.value)[0];
              setAudioURL(selectVoice.audioURL)
              setVoiceID(event.target.value)
              setVoiceLabel(event.target.value)
            }}
            size='small'
          >
            <MenuItem key={`voice-0`} value={0} disabled>
              <em>Select voice</em>
            </MenuItem>
            {voiceData.map((voice, idx) =>
              <MenuItem key={`voice-${idx}`} value={voice.value}>{voice.label}</MenuItem>
            )}
          </Select>
        </FormControl>
        {<audio controls src={audioURL} />}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDownload}
        >
          Download
        </Button>
        <LoadingButton loading={audioLoading} onClick={handleAudio} variant="outlined">
          Generate
        </LoadingButton>
      </DialogActions>

    </BootstrapDialog>
  )
}

export default AudioModal