import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Unstable_Grid2 as Grid
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, FormProvider } from 'react-hook-form';
import { object, string } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify'

import FormInput from '../../components/Form/ProfileInput'

import { fetchUser, updateUser } from '../../service/ProfileService';

// ? Profile Schema with Zod
const profileSchema = object({
  sleeperId: string().min(1, 'Sleeper ID is required').max(70),
  name: string().min(1, 'Name is required').max(70),
  email: string().min(1, 'Email is required').email('Email is invalid'),
  password: string()
    .min(1, 'Password is required')
    .min(6, 'Password must be more than 6 characters')
    .max(32, 'Password must be less than 32 characters'),
  confirm: string().min(1, 'Please confirm your password'),
}).refine((data) => data.password === data.confirm, {
  path: ['confirm'],
  message: 'Passwords do not match',
});

export function ProfileDetails() {
  const [values, setValues] = useState({});

  // ? Object containing all the methods returned by useForm
  const methods = useForm({
    resolver: zodResolver(profileSchema),
    values,
  });

  const onSubmitHandler = (values) => {
    (async () => {
      try {
        const result = await updateUser(values)
        if (result.status === 200) {
          setValues({
            ...result.data.data
          })
          toast.success(result.data.message, { autoClose: 2000 })
        }
      } catch (err) {
        toast.error(err.response.data.message, { autoClose: 2000 });
      }
    })();
  };

  useEffect(() => {
    (async () => {
      try {
        const user = await fetchUser()
        setValues({
          ...user
        })
      } catch (err) {
        console.error(err);
      }
    })();
  }, [])

  return (
    <FormProvider {...methods}>
      <Card sx={{ maxHeight: "calc(100vh - 300px)", overflow: 'auto' }}>
        <CardContent sx={{ pt: 5 }}>
          <Box
            display='flex'
            flexDirection='column'
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={methods.handleSubmit(onSubmitHandler)}
            sx={{ m: -1.5, p: 3 }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
              >
                <FormInput
                  size="small"
                  label='Sleeper ID'
                  type='text'
                  name='sleeperId'
                  focused
                  required
                  fullWidth
                />
              </Grid>

              <Grid
                xs={12}
                md={6}
              >
                <FormInput
                  size="small"
                  label='Name'
                  type='text'
                  name='name'
                  fullWidth
                  focused
                  required
                />
              </Grid>

              <Grid
                xs={12}
                md={6}
              >
                <FormInput
                  size="small"
                  label='Email'
                  type='email'
                  name='email'
                  fullWidth
                  focused
                  required
                />
              </Grid>

              <Grid
                xs={12}
                md={6}
              >
                <FormInput
                  size="small"
                  type='password'
                  label='Password'
                  name='password'
                  fullWidth
                  required
                  focused
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <FormInput
                  size="small"
                  type='password'
                  label='Confirm Password'
                  name='confirm'
                  fullWidth
                  required
                  focused
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid sx={{ display: 'flex', justifyContent: 'end' }}>
              <LoadingButton
                loading={false}
                type='submit'
                variant='contained'
                sx={{
                  py: '0.8rem',
                  mt: 2,
                  borderRadius: 2,
                  background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232))',
                }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </FormProvider>
  );
};
