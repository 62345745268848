import { tokens } from './token';
import jwtDecode from 'jwt-decode';

export const tokenDecode = () => {
  if (tokens.get()) {
    return jwtDecode(tokens.get());
  } else {
    return {};
  }
};
