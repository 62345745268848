import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const DAYS = [
  {
    key: "Sun",
    label: "Sun"
  },
  {
    key: "Mon",
    label: "Mon"
  },
  {
    key: "Tue",
    label: "Tue"
  },
  {
    key: "Wed",
    label: "Wed"
  },
  {
    key: "Thu",
    label: "Thu"
  },
  {
    key: "Fri",
    label: "Fri"
  },
  {
    key: "Sat",
    label: "Sat"
  }
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    border: '1px solid',
    borderColor: '#1677ff',
    borderRadius: '50%',
  },
  '& .MuiToggleButtonGroup-grouped:first-of-type': {
    border: '1px solid',
    borderColor: '#1677ff',
    borderRadius: '50%',
  },
  margin: theme.spacing(2),
  padding: theme.spacing(0, 1),
  gap: 10
}));

const StyledToggle = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#1677ff',
  },
  '&:hover, &.Mui-selected:hover': {
    borderColor: '#1677ff',
    backgroundColor: '#75adfa',
  },
  minWidth: 50,
  maxWidth: 50,
  height: 50,
  textTransform: 'unset',
  fontSize: '0.75rem',
  color: 'black',
}));

const ToggleDays = ({ schedule, setDayList, days, selectSchedule }) => {
  // const [days, setDays] = useState(selectSchedule);
  return (
    <>
      <StyledToggleButtonGroup
        size="large"
        arial-label="Days of the week"
        // disabled={[1]}
        value={days}
        onChange={(event, value) => { setDayList(value); }}
      >
        {DAYS.map((day, index) => (
          <StyledToggle
            size="large"
            // selected={selectSchedule.includes(day.key) ? true : false}
            disabled={!schedule.includes(day.key) ? true : false}
            key={day.key}
            value={index}
            aria-label={day.key}
          >
            {day.label}
          </StyledToggle>
        ))}
      </StyledToggleButtonGroup >
    </>
  );
};

export default ToggleDays;
