import axios from 'axios'
import { ACCESS_TOKEN_NAME } from '../config';
import { tokens } from '../utils/token';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/user/report`,
  // timeout: 200000,
  headers: {
    'Content-Type': 'application/json',
  },
})

const fetchReports = async () => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.get('/list');
    return response.data;
  } catch (err) {
    throw err;
  }
}

const runReport = async (url, data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.post('/generate/matchup/before', data)
    return response.data
  } catch (err) {
    throw err
  }
}

const getWeekData = async (data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.post('/week/list', data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const getTeamData = async (data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.post('/team/list', data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const getMatchupData = async (data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.post('/matchup/list', data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const fetchSleeperTime = async (data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.post('/sleeper', { league: data.league });
    return response.data;
  } catch (err) {
    throw err;
  }
}



export { fetchReports, runReport, getTeamData, getMatchupData, getWeekData, fetchSleeperTime }
