// @mui
import { styled, alpha } from '@mui/material/styles'
import {
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Button
} from '@mui/material'
// component
import Iconify from '../../components/iconify'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { fetchSleeperTime } from '../../service/ReportService'
import { setCurrentLeague } from '../../state/actions/LeagueAction'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { formatEST } from '../../utils/timeConvert';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 80,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}))

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}))

export default function TableListToolbar({
  filterName,
  onFilterName,
  isUse = false
}) {
  const league = useSelector(state => state.league);
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);

  const onSleeper = async () => {
    setLoading(true);
    let result = await fetchSleeperTime({ league: league.leagueId });
    setLoading(false);
    dispatch(setCurrentLeague({ lastTime: result }))
  }

  return (
    <StyledRoot>
      <StyledSearch
        size='small'
        value={filterName}
        onChange={onFilterName}
        placeholder="Search ..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: 'text.disabled', width: 20, height: 20 }}
            />
          </InputAdornment>
        }
      />
      {isUse &&
        <div className='flex items-center gap-2'>
          <span>{`Last Time: ${formatEST(league.lastTime)}`}</span>
          <LoadingButton loading={loading} onClick={() => onSleeper()} variant="contained" sx={{ background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232))', }}>
            Update
          </LoadingButton>
        </div>
      }
    </StyledRoot>
  )
}
