import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';

import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Button,
  TableContainer,
  Box
} from '@mui/material';

import { filter, isEmpty } from 'lodash';

import { TableListHead, TableListToolbar } from '../../components/table';

import { setCurrentLeague } from '../../state/actions/LeagueAction';

import { fetchLeagues } from '../../service/LeaugeService';

import FeedbackModel from './FeedbackModel'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', sort: true, },
  { id: 'season', label: 'Season', sort: true },
  { id: 'manage', label: 'Manage', sort: false },
  { id: 'feedback', label: 'Feedback', sort: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_item) => _item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const Leauge = () => {
  const dispatch = useDispatch()
  const [leagueData, setLeagueData] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('');

  const [filterName, setFilterName] = useState('');

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const leagues = await fetchLeagues();
        setLoading(false)
        setLeagueData(leagues);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [])

  const handleLeague = (e, r) => {
    navigate("/report")
  }

  const handleFeedback = () => {
    setIsModalOpen(true)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, leagueId) => {
    dispatch(setCurrentLeague({ leagueId }))
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredData = applySortFilter(leagueData, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredData.length && !!filterName;

  return (
    <div className='bg-white w-full bg-opacity-10 shadow-lg pb-6 max-w-[calc(95vw)]'>
      <Container>
        <Box
          sx={{
            width: '94%',
            ml: '3%',
            top: -50,
            mt: -3,
            mb: 3,
            p: '24px 16px',
            opacity: 1,
            background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232))',
            color: 'rgb(255, 255, 255)',
            borderRadius: '0.5rem',
            boxShadow: 'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
          }}>
          <Typography variant='h6'>Your Leagues</Typography>
        </Box>

        <Card>
          <TableListToolbar key="table-tool-bar" filterName={filterName} onFilterName={handleFilterByName} />

          <TableContainer className='px-6 pb-4' sx={{ maxHeight: "calc(100vh - 400px)" }}>
            <Table stickyHeader>
              <TableListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={leagueData.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {
                  loading ?
                    <TableRow>
                      <TableCell align="center" colSpan={12}>
                        <div className='report-loadingspinner'>
                          <RotatingLines
                            strokeColor="rgb(73, 163, 241)"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    :
                    isNotFound || isEmpty(leagueData) ?
                      <TableRow key="notFound">
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                              py: 1,
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Empty
                            </Typography>
                            {
                              isNotFound ?
                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                                :
                                ''
                            }
                          </Paper>
                        </TableCell>
                      </TableRow>
                      :
                      filteredData.map((row, idx) => {
                        const { league_id, name, season } = row;
                        return (
                          <TableRow
                            onClick={(event) => handleClick(event, league_id)}
                            hover key={league_id} tabIndex={-1}>
                            <TableCell align='center' component="th" scope="row" padding="none">
                              {name}
                            </TableCell>

                            <TableCell align='center'>{season}</TableCell>

                            <TableCell align='center' sortDirection={false}>
                              <Button sx={{ color: '#1677ff' }} size="small" variant='text' color="inherit" onClick={handleLeague}>
                                Manage Reports
                              </Button>
                            </TableCell>

                            <TableCell align='center'>
                              <Button sx={{ color: '#1677ff' }} size="small" variant='text' color="inherit" onClick={handleFeedback}>
                                Your Sideline Snippets
                              </Button>
                            </TableCell>

                          </TableRow>
                        );
                      })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <FeedbackModel open={isModalOpen} setOpen={setIsModalOpen} />
      </Container>
    </div>
  );
}

export default Leauge
