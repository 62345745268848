import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form';
import { object, string } from 'zod';

import { Grid, Box, Typography, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify'

import FormInput from '../../components/Form/FormInput';
import { forgotPassword } from '../../service/AuthService'

// ? Forgot Password Schema with Zod
const forgotPasswordSchema = object({
  email: string().min(1, 'Email is required').email('Email is invalid'),
});

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  // ? Default Values
  const defaultValues = {
    email: '',
  };

  // ? Object containing all the methods returned by useForm
  const methods = useForm({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues,
  });

  // ? Form Handler
  const onSubmitHandler = (values) => {
    setLoading(true)
    forgotPassword(values)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.data.message, { autoClose: 2000 })
      })
  };

  return (
    <div>
      <div className='w-full max-w-[410px] absolute top-[50%] left-[50%] text-left bg-[rgba(35,43,85,0.75)] py-10 transform-gpu translate-x-[-50%] translate-y-[-50%] bg-cover mt-2 mb-2 p-6 border-t border-white border-opacity-50 rounded-md shadow-md overflow-hidden transition-all ease-in-out text-white text-sm'>

        <Typography
          variant='h4'
          component='h1'
          sx={{
            textAlign: 'center',
            width: '100%',
            mb: '1.5rem',
          }}
        >
          Forgot Password
        </Typography>

        <FormProvider {...methods}>
          <Grid
            justifyContent='space-between'
          >
            <Box
              display='flex'
              flexDirection='column'
              component='form'
              noValidate
              autoComplete='off'
              onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
              <FormInput
                label='Enter your email'
                type='email'
                name='email'
                focused
                required
              />
              <Button
                type='submit'
                variant='contained'
                sx={{
                  py: '0.8rem',
                  mt: 2,
                  width: '100%',
                  marginInline: 'auto',
                  borderRadius: 2
                }}
              >
                {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : 'Forgot Password'}
              </Button>
            </Box>
          </Grid>
        </FormProvider>
      </div>
    </div>
  )
}

export default ForgotPassword
