import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Grid,
  Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close';

import { getMatchupData, getTeamData } from '../../service/ReportService';
import { runReport } from '../../service/GenerateService';
import { tokenDecode } from '../../utils/decode';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ALERT_MSG = "Please note that this report has been generated previously. If you choose to run the report again, be aware that the content may change due to updates in the data.";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  minwidth: '90%'
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize: 18 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const getURL = (value) => {
  switch (value) {
    case "Pre-Matchup Analysis Report":
      return "/matchup/before";
    case "Post-Matchup Analysis Report":
      return "/matchup/after";
    case "Power Rankings Report":
      return "/rank"
    case "Team News Analysis Report":
      return "/news"
    case "League Trade Analysis Report":
      return "/trade/league"
    case "Team Analysis Report":
      return "/team"
    case "Team Trade Analysis Report":
      return "/trade/team"
    case "Waiver Analysis Report":
      return "/trade/waiver"
    default:
      return "";
  }
}

const alertReports = ["Power Rankings Report", "League Trade Analysis Report", "Waiver Analysis Report"]

export default function RunModal({ voiceData, title, open, setOpen, selectReport }) {
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState('');

  const auth = useSelector(state => state.auth)

  // const weekData = useMemo(() => {
  //   const save = [];
  //   for (let i = 1; i <= 18; i++) {
  //     save.push(i);
  //   }
  //   return save;
  // }, []);
  // const [weeks, setWeeks] = useState([])
  // const [week, setWeek] = useState(0)

  // const [teamData, setTeamData] = useState([])
  // const [team, setTeam] = useState('')

  // const [matchupData, setMatchupData] = useState([])
  // const [matchup, setMatchup] = useState(0)

  const [loading, setLoading] = useState(false)
  const [originalLoading, setOriginalLoading] = useState(false)
  const league = useSelector(state => state.league)

  // const [audioURL, setAudioURL] = useState('')

  // const [voiceLabel, setVoiceLabel] = useState('')
  // const [voiceID, setVoiceID] = useState('')

  const [reportType, setReportType] = useState(0)

  const handleClose = async () => {
    setOpen(false);
  };

  const handleReport = async (original = 0) => {
    if (original) {
      setOriginalLoading(true)
    } else {
      setLoading(true)
    }
    if (alertReports.includes(selectReport.name) && original) {
      toast.success(ALERT_MSG, { autoClose: 3000 })
    }
    await runReport(
      getURL(selectReport.name),
      {
        // voiceId: voiceID,
        // weeks: weeks,
        // week: week,
        // matchup: matchup,
        // team: team,
        league: league.leagueId,
        reportId: selectReport._id,
        recipient: emails,
        original: original,
        reportType: reportType
      }).then(async res => {
        if (res.status === 200) {
          setLoading(false);
          setOriginalLoading(false)
          setOpen(false);
        }
      }).catch(err => console.log(err))
  };

  const handleInputChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handleAddEmail = () => {
    if (emailInput.trim() !== '') {
      setEmails([...emails, emailInput.trim()]);
      setEmailInput('');
    }
  };

  const handleRemoveEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  let tokenData = tokenDecode()

  useEffect(() => {
    (async () => {
      if (open) {
        // // const weeks = await getWeekData({ league: league.leagueId })
        // const teams = await getTeamData({ league: league.leagueId })
        // // setWeekData(weeks.map(item => item.week))
        // let teamList = Object.entries(teams)
        // let save = []
        // teamList.forEach((item) => {
        //   save.push({ value: item[0], label: item[1] })
        // })
        // setTeamData(save)
        setEmails([tokenData.email])
      }
    })()
  }, [open])

  // useEffect(() => {
  //   (async () => {
  //     if (week !== 0) {
  //       const matchups = await getMatchupData({ league: league.leagueId, week: week })
  //       let matchupList = Object.entries(matchups)
  //       let save = []
  //       matchupList.forEach((item) => {
  //         let team_text = item[1].join(' : ')
  //         save.push({ value: item[0], label: team_text })
  //       })
  //       setMatchupData(save)
  //     }
  //   })()
  // }, [week])

  // const isMatchup = (value) => {
  //   switch (value) {
  //     case "Pre-Matchup Analysis Report":
  //       return true;
  //     case "Post-Matchup Analysis Report":
  //       return true;
  //     default:
  //       return false;
  //   }
  // }

  // const isTeam = (value) => {
  //   switch (value) {
  //     case "Power Rankings Report":
  //       return true;
  //     case "Team News Analysis Report":
  //       return true;
  //     default:
  //       return false;
  //   }
  // }

  // const isWeek = (value) => {
  //   switch (value) {
  //     case "Pre-Matchup Analysis Report":
  //       return 1;
  //     case "Post-Matchup Analysis Report":
  //       return true;
  //     default:
  //       return false;
  //   }
  // }

  // const isWeeks = (value) => {
  //   switch (value) {
  //     case "Transaction Analysis Report":
  //       return true;
  //     default:
  //       return false;
  //   }
  // }

  // const handleWeeks = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setWeeks(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ minWidth: "90%" }}>
        {/* {auth.role > 1 && isWeek(selectReport.name) ?
          <Grid xs={12}
            md={12}>
            <FormControl size='small' sx={{ m: 1, width: "90%" }}>
              <InputLabel id="week-select-small-label">Week</InputLabel>
              <Select
                labelId="week-select-small-label"
                id="week-select-small"
                value={week}
                label="Week"
                onChange={(event) => setWeek(event.target.value)}
              >
                <MenuItem key={`week-0`} value={0} disabled>
                  <em>Select Week</em>
                </MenuItem>
                {
                  weekData?.map((item) => (
                    <MenuItem key={`week-${item}`} value={item}>{item}</MenuItem>
                  )
                  )
                }
              </Select>
            </FormControl>
          </Grid> : ''
        }
        {auth.role > 1 && isMatchup(selectReport.name) ?
          <Grid xs={12}
            md={12}>
            <FormControl size='small' sx={{ m: 1, width: "90%" }}>
              <InputLabel id="matchup-select-small-label">Matchup</InputLabel>
              <Select
                labelId="matchup-select-small-label"
                id="mathcup-select-small"
                value={matchup}
                label="Matchup"
                onChange={(event) => setMatchup(event.target.value)}
              >
                <MenuItem key={`matchup-0`} value={0} disabled>
                  <em>Select Matchup</em>
                </MenuItem>
                {
                  matchupData?.map((item) => (
                    <MenuItem key={`matchup-${item.value}`} value={item.value}>{item.label}</MenuItem>
                  )
                  )
                }
              </Select>
            </FormControl>
          </Grid> : ''
        }
        {auth.role > 1 && isTeam(selectReport.name) ?
          <FormControl size='small' sx={{ m: 1, width: "90%" }}>
            <InputLabel id="team-select-small-label">Team</InputLabel>
            <Select
              labelId="team-select-small-label"
              id="team-select-small"
              value={team}
              label="Team"
              onChange={(event) => setTeam(event.target.value)}
            >
              <MenuItem value={0} disabled>
                <em>Select Team</em>
              </MenuItem>
              {
                teamData?.map((item) => (
                  <MenuItem key={`team-${item.value}`} value={item.value}>{item.label}</MenuItem>
                )
                )
              }
            </Select>
          </FormControl> : ''
        }
        {auth.role > 1 && isWeeks(selectReport.name) ?
          <FormControl size='small' sx={{ m: 1, width: "90%" }}>
            <InputLabel id="week-multiple-checkbox-label">Weeks</InputLabel>
            <Select
              labelId="week-multiple-checkbox-label"
              id="week-multiple-checkbox"
              multiple
              value={weeks}
              onChange={handleWeeks}
              input={<OutlinedInput label="Weeks" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {weekData.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={weeks.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl> : ''
        } */}
        {/* <FormControl size='small' sx={{ m: 1, width: "90%" }}>
          <InputLabel id="audio-select-small-label">Audio</InputLabel>
          <Select
            labelId="audio-select-small-label"
            id="audio-select-small"
            value={voiceLabel}
            label="Audio"
            onChange={(event) => {
              const selectVoice = voiceData.filter(voice => voice.value === event.target.value)[0];
              setAudioURL(selectVoice.audioURL)
              setVoiceID(event.target.value)
              setVoiceLabel(event.target.value)
            }}
            size='small'
          >
            <MenuItem key={`voice-0`} value={0} disabled>
              <em>Select voice</em>
            </MenuItem>
            {voiceData.map((voice, idx) =>
              <MenuItem key={`voice-${idx}`} value={voice.value}>{voice.label}</MenuItem>
            )}
          </Select>
        </FormControl> */}

        {/* <audio controls src={audioURL} /> */}

        <FormControl size='small' sx={{ m: 1, width: "90%" }}>
          <InputLabel id="audio-select-small-label">Type</InputLabel>
          <Select
            labelId="audio-select-small-label"
            id="audio-select-small"
            value={reportType}
            label="Type"
            onChange={(e) => setReportType(e.target.value)}
            size='small'
          >
            <MenuItem key={"text"} value={0}>Text
            </MenuItem>
            {tokenData.result_type && <MenuItem key={"text-audio"} value={1}>Text & Audio</MenuItem>}
          </Select>
        </FormControl>
        <Grid sx={{ m: 1, minwidth: "90%" }}>
          <TextField
            size='small'
            label="Email"
            variant="outlined"
            value={emailInput}
            onChange={handleInputChange}
          />
          <IconButton variant="contained" onClick={handleAddEmail}><AddIcon fontSize="inherit" /></IconButton>
        </Grid>
        <Grid sx={{ m: 1, paddingLeft: 2 }}>
          <ul>
            {emails.map((email, index) => (
              <li key={index}>
                {email}
                <IconButton
                  variant="outlined"
                  onClick={() => handleRemoveEmail(index)}
                  sx={{ marginLeft: '10px' }}
                  size='small'
                ><DeleteIcon fontSize="inherit" /></IconButton>
              </li>
            ))}
          </ul>
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          alertReports.includes(selectReport.name) ?
            <LoadingButton
              loading={originalLoading}
              onClick={() => handleReport(1)}
              sx={{ background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232))', }}
              variant="contained"
            >
              Original Report
            </LoadingButton> : ""
        }
        <LoadingButton
          loading={loading}
          onClick={() => handleReport()}
          variant="contained"
          sx={{ background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232))', }}
        >
          Run Now
        </LoadingButton>
      </DialogActions>
    </BootstrapDialog>
  );
}