import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from "../routes"
import { tokens } from '../utils/token';
import { tokenDecode } from '../utils/decode';
import { Typography, IconButton, Divider } from '@mui/material'
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useMemo } from 'react';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { updateNotification } from '../service/NotificationService';
import { setCurrentUser } from '../state/actions/AuthAction';
import { formatEST } from '../utils/timeConvert';
import { setCurrentReportLog } from '../state/actions/NotificationAction';


export default function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth)
  const [openNav, setOpenNav] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [notify, setNotify] = React.useState(null);
  const openNotify = Boolean(notify);
  const onNotifyClick = (event) => {
    setNotify(event.currentTarget);
  };
  const onNotifyClose = () => {
    setNotify(null);
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const handleNotification = async (notification, isRemove) => {
    try {
      console.log(notification);
      if (isRemove) {
        let notificationList = auth.notifications;
        dispatch(setCurrentUser({ notifications: notificationList.filter(item => item._id !== notification._id) }));
        const result = await updateNotification({ id: notification._id });
        if (result.status !== 200) {
          dispatch(setCurrentUser({ notifications: notificationList }));
        }
      } else {
        dispatch(setCurrentReportLog({ log_id: notification?.reportlog_id }));
        navigate("/reportlog")
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleLogout = (e) => {
    e.preventDefault();
    setOpenNav(false);
    tokens.remove();
    navigate('/login', { replace: true });
  };

  return (
    <nav className="block w-full max-w-screen-2xl rounded-xl bg-transparent text-white shadow-none p-4">
      <div className="w-full mx-auto flex items-center justify-between text-white">
        <Link to="/league">
          <img
            src="/logo.png"
            alt="model"
            className="max-h-[68px] rounded-none" />
        </Link>
        <div>
          <div className={`flex items-center gap-2`}>
            {!tokens.get() ?
              <>
                <Link className={`hidden lg:flex items-center px-4 py-2 rounded text-base font-normal bg-gradient`} to="/login">
                  Log in
                </Link>
                <Link className={`hidden lg:flex items-center px-4 py-2 rounded text-base font-normal bg-gradient`} to="/signup">
                  Sign up
                </Link>
              </>
              :
              <div className='flex gap-4 items-center'>
                <div className={`${openNav ? "hidden" : ""}`}>
                  <Badge badgeContent={auth.notifications?.length || 0} color="primary" onClick={onNotifyClick} className='cursor-pointer'>
                    <MailIcon color="action" />
                  </Badge>
                  <Menu
                    anchorEl={notify}
                    id="account-menu"
                    open={openNotify}
                    onClose={onNotifyClose}
                    onClick={onNotifyClose}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          maxHeight: 80 * 3.2,
                          overflow: 'auto',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    {auth.notifications?.length ? (
                      auth.notifications?.map(item => (
                        <div key={item._id}>
                          <div className='w-full flex min-w-[320px] max-w-[320px] gap-1 px-2 py-1'>
                            <span className='flex text-xs font-normal cursor-pointer' onClick={() => handleNotification(item, false)}>
                              {item.message}
                            </span>
                            <IconButton
                              aria-label="delete"
                              className='flex w-6 h-6 p-1'
                              onClick={() => handleNotification(item, true)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                          <div className='w-full flex pr-4 justify-end text-xs font-normal'>{formatEST(item.created_at)}</div>
                          <Divider className='w-full p-1' />
                        </div>
                      ))
                    ) : (
                      <div className='w-full flex min-w-[320px] max-w-[320px] justify-center'>Empty</div>
                    )}
                  </Menu>
                </div>
                <div className={`hidden lg:flex`}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    className='bg-gradient'
                  >
                    {tokenDecode()?.name}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    {routes.map(({ name, path, icon, href, target }) => (
                      <MenuItem key={name}>
                        <Link to={path} className='w-full'>{name}</Link>
                      </MenuItem>
                    ))}
                    <Divider />
                    <MenuItem key="logout">
                      <Link className='w-full flex items-center font-normal' onClick={handleLogout}>
                        Log out
                      </Link>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            }
            <div className='lg:hidden'>
              <IconButton
                variant="text"
                size="small"
                color="inherit"
                className="ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent"
                onClick={() => setOpenNav(!openNav)}
              >
                {openNav ? (
                  <XMarkIcon strokeWidth={2} className="h-6 w-6" />
                ) : (
                  <Bars3Icon strokeWidth={2} className="h-6 w-6 " />
                )}
              </IconButton>
            </div>
          </div>
        </div>

      </div>
      <div
        className={`block header-nav w-full basis-full overflow-hidden rounded-xl px-4 pt-2 pb-4 text-blue-gray-900 ${openNav ? 'is-opened' : 'is-closed'}`}
      >
        <div className="container mx-auto">
          <ul className="mb-4 mt-2 flex flex-col gap-2 text-inherit lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            {tokens.get() && routes.map(({ name, path, icon, href, target }) => (
              <Typography
                key={name}
              >
                <Link
                  to={path}
                  target={target}
                  className="flex items-center gap-1 p-1 font-normal hover:bg-gray-500 hover:underline-offset-8"
                  onClick={() => setOpenNav(false)}
                >
                  {icon &&
                    React.createElement(icon, {
                      className: "w-[18px] h-[18px] opacity-75 mr-1",
                    })}
                  {name}
                </Link>
              </Typography>
            ))}
          </ul>
          {openNav && (tokens.get() ?
            <Typography key="logout">
              <Link className="flex items-center gap-1 p-1 font-normal text-black hover:underline hover:underline-offset-8"
                onClick={handleLogout} >
                Log out
              </Link>
            </Typography> :
            <>
              <Typography key="login">
                <Link className="flex items-center gap-1 p-1 font-normal text-black hover:underline hover:underline-offset-8"
                  onClick={() => setOpenNav(false)} to="/login">
                  Log in
                </Link>
              </Typography>
              <Typography key="signup">
                <Link className="flex items-center gap-1 p-1 font-normal text-black hover:underline hover:underline-offset-8"
                  onClick={() => setOpenNav(false)} to="/signup">
                  Sign up
                </Link>
              </Typography>
            </>
          )}
        </div>
      </div>
    </nav >
  );
}