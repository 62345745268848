import axios from 'axios'
import { ACCESS_TOKEN_NAME } from '../config';
import { tokens } from '../utils/token';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/user/generate`,
  // timeout: 200000,
  headers: {
    'Content-Type': 'application/json',
  },
})

const runReport = async (url, data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.post(url, data)
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

const sendEmail = async (data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.post("/send/email", data)
    return response;
  } catch (err) {
    throw err;
  }
}

export { runReport, sendEmail }