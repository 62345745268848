import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form';
import { literal, object, string } from 'zod';

import {
  Grid,
  Box,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from '@emotion/styled';
import { toast } from 'react-toastify'

import FormInput from '../../components/Form/FormInput';
import { login_service } from '../../service/AuthService'
import { setCurrentUser } from '../../state/actions/AuthAction'
import { fetchNotification } from '../../service/NotificationService';

// ? Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 4;
  }
`;

// ? Login Schema with Zod
const loginSchema = object({
  sleeperId: string().min(1, 'Sleeper ID is required').max(70),
  password: string()
    .min(1, 'Password is required')
    .min(6, 'Password must be more than 6 characters')
    .max(32, 'Password must be less than 32 characters'),
  persistUser: literal(true).optional(),
});

const Login = () => {
  // const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  // ? Default Values
  const defaultValues = {
    email: '',
    password: '',
  };

  // ? The object returned from useForm Hook
  const methods = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues,
  });

  // ? Submit Handler
  const onSubmitHandler = (values) => {
    setLoading(true)
    login_service(values)
      .then(async (res) => {
        setLoading(false)
        if (res.status === 200) {
          dispatch(setCurrentUser({ role: res.data.role }))
          navigate('/league')
          const response = await fetchNotification();
          dispatch(setCurrentUser({ notifications: response.data.data }));
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message, { autoClose: 2000 })
      })
  };


  return (
    <div>
      <div className='w-full max-w-[410px] absolute top-[50%] left-[50%] text-left bg-[rgba(35,43,85,0.75)] py-10 transform-gpu translate-x-[-50%] translate-y-[-50%] bg-cover mt-2 mb-2 p-6 border-t border-white border-opacity-50 rounded-md shadow-md overflow-hidden transition-all ease-in-out text-white text-sm'>
        <Typography
          variant='h4'
          component='h1'
          sx={{
            textAlign: 'center',
            width: '100%',
            mb: '1.5rem',
          }}
        >
          Login
        </Typography>

        <FormProvider {...methods}>
          <Grid
            justifyContent='space-between'
          >
            <Box
              display='flex'
              flexDirection='column'
              component='form'
              noValidate
              autoComplete='off'
              onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
              <FormInput
                label='Sleeper ID'
                type='text'
                name='sleeperId'
                focused
                required
              />
              <FormInput
                type='password'
                label='Password'
                name='password'
                required
                focused
              />

              <LinkItem to='/forgot-password' className='text-base'>Forgot Password?</LinkItem>
              {/* <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  aria-label='trust this device checkbox'
                  required
                  {...methods.register('persistUser')}
                />
              }
              label={
                <Typography
                  variant='body2'
                  sx={{
                    fontSize: '0.8rem',
                    fontWeight: 400,
                    color: '#5e5b5d',
                  }}
                >
                  Trust this device
                </Typography>
              }
            /> */}

              <Button
                type='submit'
                variant='contained'
                sx={{
                  py: '0.8rem',
                  mt: 2,
                  width: '100%',
                  marginInline: 'auto',
                  borderRadius: 2,
                }}
              >
                {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : 'Login'}
              </Button>
            </Box>
          </Grid>
        </FormProvider>

        <Stack sx={{ mt: 2, textAlign: 'center', borderTop: 1, pt: 2 }}>
          <Typography sx={{ fontSize: '1rem', mb: '1rem' }} className='flex gap-2 justify-center'>
            Don't have an account?
            <LinkItem to='/signup'>Sign up here</LinkItem>
          </Typography>
        </Stack>
      </div>
    </div>
  )
}

export default Login
