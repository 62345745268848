import React, { useState, useEffect } from 'react';

import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Backdrop
} from '@mui/material';

import { RotatingLines } from 'react-loader-spinner';

import PreviewIcon from '@mui/icons-material/Preview';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import moment from 'moment';
// import { filter } from 'lodash';

import AudioModal from './AudioModal';
import { TableListHead, TableListToolbar } from '../../components/table';

import { fetchReportLog } from '../../service/ReportLogService';

import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';
import { formatEST } from '../../utils/timeConvert';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'report_name', label: 'Report Name', sort: true },
  { id: 'run_date', label: 'Date', sort: true },
  { id: 'viewAction', label: 'Last Report', sort: false },
  { id: 'textAction', label: 'Text', sort: false },
  { id: 'audioAction', label: 'Audio', sort: false }
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

const ReportLog = () => {
  const notification = useSelector(state => state.notification)
  const [modalOpen, setModalOpen] = useState(false)

  const [voiceData, setVoiceData] = useState([]);

  const [reportLogData, setReportLogData] = useState([])

  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('run_date');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loading, setLoading] = useState(false)

  const [reportLogId, setReportLogId] = useState("");

  const [selectLog, setSelectLog] = useState({})

  const [open, setOpen] = useState(false)

  const [content, setContent] = useState("")

  useEffect(() => {
    (async () => {

      try {
        setLoading(true)
        const reportLogs = await fetchReportLog({ current: page, pageSize: rowsPerPage, filterName: filterName });
        setLoading(false)
        setTotal(reportLogs.total)
        setReportLogData(reportLogs.list);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [page, rowsPerPage, filterName])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = reportLogData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reportLogData.length) : 0;

  // const filteredUsers = applySortFilter(reportLogData, getComparator(order, orderBy), filterName);

  const isNotFound = !reportLogData.length && !!filterName;

  return (
    <div className='bg-white bg-opacity-10 shadow-lg pb-6 max-w-[calc(95vw)] w-full'>
      <Container>
        <Box sx={{
          width: '94%',
          ml: '3%',
          top: -50,
          mt: -3,
          mb: 3,
          p: '24px 16px',
          opacity: 1,
          background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232))',
          color: 'rgb(255, 255, 255)',
          borderRadius: '0.5rem',
          boxShadow: 'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
        }}>
          <Typography variant='h6'>FantasyCast Archive</Typography>
        </Box>

        <Card>
          <TableListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <TableContainer className='px-6' sx={{ maxHeight: "calc(100vh - 400px)" }}>
            <Table stickyHeader>
              <TableListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={reportLogData.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {
                  loading ?
                    <TableRow>
                      <TableCell align="center" colSpan={12}>
                        <div className='report-loadingspinner'>
                          <RotatingLines
                            strokeColor="rgb(73, 163, 241)"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    :
                    isNotFound || isEmpty(reportLogData) ?
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                              py: 1
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Empty
                            </Typography>

                            {
                              isNotFound ?
                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                                :
                                ''
                            }
                          </Paper>
                        </TableCell>
                      </TableRow>
                      :
                      reportLogData.map((row) => {
                        const { _id, report_name, run_date, is_file, content } = row;
                        return (
                          <TableRow hover key={_id} tabIndex={-1} className={`${notification.log_id === _id ? "bg-primary/70" : ""}`}>
                            <TableCell align='center' component="th" scope="row" padding="none">
                              {report_name}
                            </TableCell>

                            <TableCell align='center'>{formatEST(run_date)}</TableCell>

                            <TableCell align='center' sortDirection={false}>
                              <IconButton sx={{ color: '#1677ff' }} size="small" color="inherit" onClick={() => { setOpen(true); setContent(content) }}>
                                <PreviewIcon />
                              </IconButton>
                            </TableCell>

                            <TableCell align='center' sortDirection={false}>
                              {content ?
                                <IconButton sx={{ color: '#1677ff' }} size="small" color="inherit"
                                  onClick={() => {
                                    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
                                    saveAs(blob, 'report.txt');
                                    // const a = document.createElement('a')
                                    // let url = `${process.env.REACT_APP_SERVER_URL}/api/user/reportlog/text/download/${_id}`
                                    // a.href = url
                                    // a.download = url.split('/').pop()
                                    // a.click()
                                    // setModalOpen(true);
                                    // setReportLogId(_id);
                                    // setSelectLog(row)
                                  }}>
                                  <FileDownloadIcon />
                                </IconButton> : "No Content"
                              }
                            </TableCell>
                            <TableCell align='center' sortDirection={false}>
                              {is_file ?
                                <IconButton sx={{ color: '#1677ff' }} size="small" color="inherit"
                                  onClick={() => {
                                    const a = document.createElement('a')
                                    let url = `${process.env.REACT_APP_SERVER_URL}/api/user/reportlog/audio/download/${_id}`
                                    a.href = url
                                    a.download = "report.mp3";
                                    // a.download = url.split('/').pop()
                                    document.body.appendChild(a); // Append to body to ensure visibility
                                    a.click()
                                    document.body.removeChild(a); // Clean up
                                    setReportLogId(_id);
                                    setSelectLog(row)
                                  }}>
                                  <CloudDownloadIcon />
                                </IconButton> : "No File"
                              }
                            </TableCell>

                          </TableRow>
                        );
                      })
                }
                {/* {emptyRows > 0 && (
                        <TableRow key="emptyRows" style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            className='pl-6 pb-4'
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Backdrop
          sx={{ color: '#fff', backgroundColor: "#3683dc", overflow: "auto", pt: 8, zIndex: (theme) => theme.zIndex.drawer + 1, alignItems: 'baseline' }}
          open={open}
          onClick={() => setOpen(false)}
        >
          <Typography variant="caption" display="block" gutterBottom sx={{ paddingLeft: 5, paddingRight: 5, fontSize: 16 }}>
            {content}
          </Typography>
        </Backdrop>
      </Container>
      <AudioModal voiceData={voiceData} open={modalOpen} setOpen={setModalOpen} reportlogId={reportLogId} rowData={selectLog} />
    </div>
  );
}

export default ReportLog
