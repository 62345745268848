import axios from 'axios'
import { ACCESS_TOKEN_NAME } from '../config';
import { tokens } from '../utils/token';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/user/notification`,
  headers: {
    'Content-Type': 'application/json',
  },
})

const fetchNotification = async () => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.get('/list');
    return response;
  } catch (err) {
    throw err;
  }
}

const updateNotification = async (data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.put(`/update/${data.id}`, { is_read: true });
    return response;
  } catch (err) {
    throw err;
  }
}

export { fetchNotification, updateNotification }