import axios from 'axios'
import { ACCESS_TOKEN_NAME } from '../config';
import { tokens } from '../utils/token';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/user/profile`,
  headers: {
    'Content-Type': 'application/json',
  },
})

const fetchUser = async () => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.get('/');
    return response.data.data;
  } catch (err) {
    throw err;
  }
}

const updateUser = async (data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.post('/update', data);
    return response;
  } catch (err) {
    throw err;
  }
}

export { fetchUser, updateUser }