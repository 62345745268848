import axios from 'axios'
import { ACCESS_TOKEN_NAME } from '../config';
import { tokens } from '../utils/token';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/user/feedback`,
  headers: {
    'Content-Type': 'application/json',
  },
})

const fetchFeedback = async (data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.get('/list', { params: data });
    return response.data.data;
  } catch (err) {
    throw err;
  }
}

const updateFeedback = async (data) => {
  try {
    apiClient.defaults.headers.common[ACCESS_TOKEN_NAME] = tokens.get()
    const response = await apiClient.post('/update', data);
    return response.data.data;
  } catch (err) {
    throw err;
  }
}

export { fetchFeedback, updateFeedback }
