import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form';
import { object, string } from 'zod';

import { Grid, Box, Typography, Stack, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify'

import FormInput from '../../components/Form/FormInput';
import { LinkItem } from './Login';
import { register_service } from '../../service/AuthService'

// ? SignUp Schema with Zod
const signupSchema = object({
  sleeperId: string().min(1, 'Sleeper ID is required').max(70),
  name: string().min(1, 'Name is required').max(70),
  email: string().min(1, 'Email is required').email('Email is invalid'),
  password: string()
    .min(1, 'Password is required')
    .min(6, 'Password must be more than 6 characters')
    .max(32, 'Password must be less than 32 characters'),
  confirm: string().min(1, 'Please confirm your password'),
}).refine((data) => data.password === data.confirm, {
  path: ['confirm'],
  message: 'Passwords do not match',
});


const Register = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  // ? Default Values
  const defaultValues = {
    sleeperId: '',
    name: '',
    email: '',
    password: '',
    confirm: '',
  };

  // ? Object containing all the methods returned by useForm
  const methods = useForm({
    resolver: zodResolver(signupSchema),
    defaultValues,
  });

  // ? Form Handler
  const onSubmitHandler = (values) => {
    setLoading(true)
    register_service(values)
      .then((res) => {
        setLoading(false)
        if (res.status === 201) {
          navigate('/login')
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.data.message, { autoClose: 2000 })
      })
  };

  return (
    <div>
      <div className='w-full max-w-[410px] absolute top-[50%] left-[50%] text-left bg-[rgba(35,43,85,0.75)] py-10 transform-gpu translate-x-[-50%] translate-y-[-50%] bg-cover mt-2 mb-2 p-6 border-t border-white border-opacity-50 rounded-md shadow-md overflow-hidden transition-all ease-in-out text-white text-sm'>

        <Typography
          variant='h4'
          component='h1'
          sx={{
            textAlign: 'center',
            width: '100%',
            mb: '1.5rem',
          }}
        >
          Signup
        </Typography>

        <FormProvider {...methods}>
          <Grid
            justifyContent='space-between'
          >
            <Box
              display='flex'
              flexDirection='column'
              component='form'
              noValidate
              autoComplete='off'
              onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
              <FormInput
                label='Sleeper ID'
                type='text'
                name='sleeperId'
                focused
                required
              />
              <FormInput
                label='Name'
                type='text'
                name='name'
                focused
                required
              />
              <FormInput
                label='Enter your email'
                type='email'
                name='email'
                focused
                required
              />
              <FormInput
                type='password'
                label='Password'
                name='password'
                required
                focused
              />
              <FormInput
                type='password'
                label='Confirm Password'
                name='confirm'
                required
                focused
              />

              <Button
                type='submit'
                variant='contained'
                sx={{
                  py: '0.8rem',
                  mt: 2,
                  width: '100%',
                  marginInline: 'auto',
                  borderRadius: 2
                }}
              >
                {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : 'Sign Up'}
              </Button>
            </Box>
          </Grid>
        </FormProvider>

        <Stack sx={{ mt: 2, textAlign: 'center', borderTop: 1, pt: 2 }}>
          <Typography sx={{ fontSize: '1rem', mb: '1rem' }} className='flex gap-2 justify-center'>
            Have an account? <LinkItem to='/login'>Login</LinkItem>
          </Typography>
        </Stack>

      </div>
    </div>
  )
}

export default Register
