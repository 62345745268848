import { SET_CURRENT_LEAGUE } from '../actions/types';

const initialState = {
  leagueId: "",
  lastTime: null
};

const LeagueReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_LEAGUE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default LeagueReducer