import React, { useState, useMemo, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { getTeamData } from '../../service/ReportService';
import { fetchFeedback, updateFeedback } from "../../service/FeedbackService"
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

export default function FeedbackModel({ open, setOpen }) {
  const auth = useSelector(state => state.auth)

  const [nowWeek, setNowWeek] = useState([])
  const weekData = useMemo(() => {
    const save = [];
    for (let i = 1; i <= nowWeek; i++) {
      save.push(i);
    }
    return save;
  }, [nowWeek]);
  const [week, setWeek] = useState(0)

  const [teamData, setTeamData] = useState([])
  const [team, setTeam] = useState('')

  const [feedbackData, setFeedbackData] = useState([])
  const [feedback, setFeedback] = useState({})
  const [comment, setComment] = useState("")

  const [loading, setLoading] = useState(false)
  const league = useSelector(state => state.league)

  const handleModalClose = async () => {
    setOpen(false);
  };

  useEffect(() => {
    setTeamData([]);
    setFeedbackData([]);
    setComment("")
    setFeedback({})
  }, []);

  useEffect(() => {
    (async () => {
      if (open) {
        const teams = await getTeamData({ league: league.leagueId })
        const feedbacks = await fetchFeedback({ league: league.leagueId })
        let teamList = Object.entries(teams.list)
        setNowWeek(teams.week)
        let save = []
        teamList.forEach((item) => {
          save.push({ value: item[0], label: item[1] })
        })
        setTeamData(save)

        setFeedbackData(feedbacks)
      }
    })()
  }, [open])

  useEffect(() => {
    if (open) {
      let selectTeam = teamData.filter(teamItem => teamItem.value === team)[0]
      let result = feedbackData?.filter((item => item.league_id === league.leagueId && item.week === week && selectTeam?.label === item.target_team))[0];

      if (result && result.comment)
        setComment(result.comment)
      else setComment("")
      setFeedback(result)
    }
  }, [team, week])

  const handleFeedback = async () => {
    try {
      setLoading(true)
      const result = await updateFeedback({ id: feedback?._id, league: league.leagueId, week: week, target_team: team, comment })
      setLoading(false)
      let updatedData = result
      setFeedback(updatedData)

      const feedbackExist = feedbackData.some(item => item._id === updatedData._id);
      if (feedbackExist) {
        setFeedbackData(prev => prev?.map(feed =>
          feed._id === updateFeedback._id ? updatedData : feed
        ))
      } else {
        setFeedbackData(prev => [...prev, updatedData])
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Dialog
      onClose={handleModalClose}
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
        Your Sideline Snippets
      </DialogTitle>

      <DialogContent dividers sx={{ alignItems: "center", minWidth: "100%" }}>
        <FormControl size='small' sx={{ mt: 1, mb: 1, width: "100%" }}>
          <InputLabel id="team-select-small-label">Team</InputLabel>
          <Select
            labelId="team-select-small-label"
            id="team-select-small"
            value={team}
            label="Team"
            onChange={(e) => setTeam(e.target.value)}
          >
            <MenuItem value={0} disabled>
              <em>Select Team</em>
            </MenuItem>
            {
              teamData?.map((item) => (
                <MenuItem key={`team-${item.value}`} value={item.value}>{item.label}</MenuItem>
              )
              )
            }
          </Select>
        </FormControl>

        <FormControl size='small' sx={{ mt: 1, mb: 1, width: "100%" }}>
          <InputLabel id="week-select-small-label">Week</InputLabel>
          <Select
            labelId="week-select-small-label"
            id="week-select-small"
            value={week}
            label="Week"
            onChange={(event) => setWeek(event.target.value)}
          >
            <MenuItem key={`week-0`} value={0} disabled>
              <em>Select Week</em>
            </MenuItem>
            {
              weekData?.map((item) => (
                <MenuItem key={`week-${item}`} value={item}>{item}</MenuItem>
              )
              )
            }
          </Select>
        </FormControl>

        <TextField
          sx={{ mt: 1, mb: 1, width: "100%" }}
          id="filled-multiline-static"
          label="Feedback"
          multiline
          rows={4}
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          variant="filled"
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton loading={loading} onClick={handleFeedback} variant="contained" sx={{ background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232))', }}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}