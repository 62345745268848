import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Typography,
  Button,
  Box,
  Container,
  Card,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';

import { RotatingLines } from 'react-loader-spinner';

import { filter, isEmpty } from 'lodash';

import ScheduleModal from './ScheduleModal';
import RunModal from './RunModal';
import { TableListHead, TableListToolbar } from "../../components/table"

import { fetchReports } from '../../service/ReportService';
// import { getSchedule } from '../../service/ScheduleService'
// import { fetchVoices } from "../../service/ReportLogService"
import { setCurrentLeague } from '../../state/actions/LeagueAction';
import { tokenDecode } from '../../utils/decode';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Report Name', sort: true },
  { id: 'action', label: 'Action', sort: false },
  { id: 'schedule', label: 'Schedule', sort: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_item) => _item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Report() {
  const dispatch = useDispatch()
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [runOpen, setRunOpen] = useState(false)

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('');

  const [filterName, setFilterName] = useState('');

  const [loading, setLoading] = useState(false)

  const [reportData, setReportData] = useState([])
  const [voiceData, setVoiceData] = useState([]);

  const [selectReport, setSelectReport] = useState({});
  const [userSchedule, setUserSchedule] = useState({});
  const [userVoice, setUserVoice] = useState({});

  const league = useSelector(state => state.league)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      try {
        if (league.leagueId) {
          setLoading(true)
          const reports = await fetchReports();
          // const voices = await fetchVoices();
          // const schedules = await getSchedule({ league: league.leagueId })
          setLoading(false);
          // let save = voices?.map((item) => {
          //   return {
          //     value: item['voice_id'],
          //     label: item['name'],
          //     audioURL: item['preview_url'],
          //   }
          // })
          // setVoiceData([
          //   // { value: '0', label: 'Select voice', disabled: true },
          //   ...save,
          // ])
          // setUserVoice(schedules?.report_voice)
          // setUserSchedule(schedules?.report_schedule)
          setReportData(reports.data);
          dispatch(setCurrentLeague({ lastTime: reports.lastTime }))
          // setLastTime(reports.lastTime);
        } else {
          navigate("/league")
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredData = applySortFilter(reportData, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredData.length && !!filterName;

  return (
    <>
      <div className='bg-white bg-opacity-10 shadow-lg pb-6 max-w-[calc(95vw)] w-full'>
        <Container>
          <Box sx={{
            width: '94%',
            ml: '3%',
            top: -50,
            mt: -3,
            mb: 3,
            p: '24px 16px',
            opacity: 1,
            background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26,115,232))',
            color: 'rgb(255, 255, 255)',
            borderRadius: '0.5rem',
            boxShadow: 'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem',
          }}>
            <Typography variant='h6'>Manage Reports</Typography>
          </Box>
          <Button key="back-button" sx={{ color: 'white' }} size='large' onClick={() => navigate("/league")}>
            Back
          </Button>
          <Button key="manage-button" size='large' sx={{ float: 'right', color: "white" }} variant='text' onClick={() => navigate("/archive")}>
            Manage Leagues
          </Button>

          <Card key="report-data">
            <TableListToolbar key="table-tool-bar" filterName={filterName} onFilterName={handleFilterByName} isUse={true} />

            <TableContainer className='px-4 pb-4' sx={{ maxHeight: "calc(100vh - 400px)" }}>
              <Table stickyHeader>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={reportData.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {
                    loading ?
                      <TableRow>
                        <TableCell align="center" colSpan={12}>
                          <div className='report-loadingspinner'>
                            <RotatingLines
                              strokeColor="rgb(73, 163, 241)"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="96"
                              visible={true}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      :
                      isNotFound || isEmpty(reportData) ?
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                                py: 1
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Empty
                              </Typography>
                              {
                                isNotFound ?
                                  <Typography variant="body2">
                                    No results found for &nbsp;
                                    <strong>&quot;{filterName}&quot;</strong>.
                                    <br /> Try checking for typos or using complete words.
                                  </Typography>
                                  :
                                  ''
                              }
                            </Paper>
                          </TableCell>
                        </TableRow>
                        :
                        filteredData.map((row) => {
                          const { _id, name, description, show_schedule, schedule_list } = row;
                          return (
                            <TableRow hover key={_id} tabIndex={-1} >
                              <TableCell align='center'>
                                <Typography variant="subtitle1">
                                  {description}
                                </Typography>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {name}
                                </Typography>
                              </TableCell>
                              <TableCell align='center' >
                                {tokenDecode()?.report_list && tokenDecode()?.report_list.includes(_id) ?
                                  <Button
                                    sx={{ color: '#1677ff' }}
                                    size="small"
                                    variant='text'
                                    color="inherit"
                                    onClick={() => {
                                      setSelectReport(row);
                                      setRunOpen(true);
                                    }}
                                  >
                                    Run Now
                                  </Button>
                                  :
                                  ''
                                }
                                {/* <Button
                                  sx={{ color: '#1677ff' }}
                                  size="small"
                                  variant='text'
                                  color="inherit"
                                  onClick={() => {
                                    setSelectReport(row);
                                    setScheduleOpen(true);
                                  }}>
                                  Schedule
                                </Button> */}
                              </TableCell>
                              <TableCell align='center'>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {show_schedule && schedule_list.map(item => item).join(", ")}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Container>
      </div>

      <ScheduleModal
        voiceData={voiceData}
        title={selectReport.name}
        reportId={selectReport._id}
        open={scheduleOpen}
        setOpen={setScheduleOpen}
        schedule={selectReport.schedule_list}
        userVoice={userVoice}
        setUserVoice={setUserVoice}
        userSchedule={userSchedule}
        setUserSchedule={setUserSchedule}
      />
      <RunModal voiceData={voiceData} title={selectReport.name} open={runOpen} setOpen={setRunOpen} selectReport={selectReport} />
    </>
  );
}