import React from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import League from './pages/League';
import Profile from './pages/Profile';
import ReportLog from './pages/ReportLog';
import Archive from './pages/Archive';
import Report from './pages/Report';
import MainLayout from './layouts';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import NotFound from './pages/NotFound';
import { tokens } from './utils/token';
import { tokenDecode } from './utils/decode';

const isTokenValid = () => {
  const token = tokenDecode();
  return token && token.exp * 1000 > Date.now();
};

const RequireAuth = () => {
  if (!tokens.get() || !isTokenValid()) {
    tokens.remove();
    return <Navigate to="/login" replace />;
  }
  return <Outlet />;
};

const CheckAuth = () => {
  if (tokens.get() && isTokenValid()) {
    return <Navigate to="/league" replace />;
  }
  return <Outlet />;
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        element: <CheckAuth />,
        children: [
          { path: 'login', element: <Login /> },
          { path: 'signup', element: <Register /> },
          { path: 'forgot-password', element: <ForgotPassword /> },
          { path: 'reset-password', element: <ResetPassword /> },
        ],
      },
      {
        element: <RequireAuth />,
        children: [
          { index: true, element: <League /> },
          { path: 'league', element: <League /> },
          { path: 'archive', element: <Archive /> },
          { path: 'report', element: <Report /> },
          { path: 'profile', element: <Profile /> },
          { path: 'reportlog', element: <ReportLog /> },
        ],
      },
    ],
  },
  {
    path: '/*',
    element: <NotFound />,
  },
]);

export const routes = [
  {
    name: 'Your Leagues',
    key: 'league',
    path: '/league',
  },
  {
    name: 'FantasyCast Profile',
    key: 'profile',
    path: '/profile',
  },
  {
    name: 'FantasyCast Archive',
    key: 'reportlog',
    path: '/reportlog',
  },
];
