const setCookie = (cookieName, cookieValue) => {
  window.localStorage.setItem(cookieName, JSON.stringify(cookieValue))
}
const getCookie = (cookieName) => {
  const result = window.localStorage.getItem(cookieName)
  return JSON.parse(result)
}
const deleteCookie = (cookieName) => {
  window.localStorage.removeItem(cookieName)
  return true
}

export { setCookie, getCookie, deleteCookie }
